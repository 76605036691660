








import { Component, Mixins } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject, IRelatedService, Loading, RelatedServiceType } from '../../../front'

import { AbstractModuleUi } from '../../abstract/ui'

import { VideoModule } from '../Video.contracts'

import YoutubeUi from './versions/youtube/Youtube.ui.vue'

/**
 * Component capable of rendering the `EmbedModule`'s contents.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<VideoModuleUi>({
  name: 'VideoModuleUi',
  components: { YoutubeUi },
  mounted () {
    this.markAsReady()
  }
})
export class VideoModuleUi extends Mixins<AbstractModuleUi<VideoModule>, Loading>(
  AbstractModuleUi,
  Loading
) {
  @Inject(EventbusType)
  public eventBus!: IEventbus

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService
}

export default VideoModuleUi






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'

import { ImageProps } from '../../../../../dsl/atoms/Image'

import { toImageProps } from '../../../../../front/shared/support/'

import { IRelatedService } from '../../../../../contexts'

import {
  VideoModuleContent
} from '../../../Video.contracts'

import { UiMarkdown } from '../../../../partials/UiMarkdown'
import YouTubeModal from '../../../../partials/YouTubeModal/YoutubeModal.vue'

/**
 * Component capable of rendering the `EmbedModule`
 * with the version set to `EmbedVersion.YouTube`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<YouTubeUi>({
  name: 'YouTubeUi',
  components: { UiMarkdown, YouTubeModal },
  mounted (): void {
    this.videoIdentifier = this.getIdentifier(this.videoId!, String(Math.random() * 1000))
  }
})

export class YouTubeUi extends Vue {
  @Prop({ type: Boolean, required: false })
  protected readonly autoplay?: boolean

  @Prop({ type: String, required: false })
  public readonly title?: VideoModuleContent['title']

  @Prop({ type: String, required: false })
  public readonly description?: VideoModuleContent['description']

  @Prop({ type: Object, required: true })
  public readonly eventBus?: IEventbus

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: false })
  public readonly thumbnail?: VideoModuleContent['thumbnail']

  @Prop({ type: String, required: true })
  public readonly videoId!: VideoModuleContent['videoId']

  public videoIdentifier: string | null = null

  public getIdentifier (videoId: string, index: string) {
    return `${videoId}-${index}`
  }

  public get image (): ImageProps {
    if (!this.thumbnail) {
      return this.youTubeCover
    }

    return toImageProps(this.thumbnail)
  }

  public get youTubeCover (): ImageProps {
    return {
      src: `https://img.youtube.com/vi/${this.videoId}/0.jpg`,
      alt: 'YouTube cover video'
    }
  }

  public get hasVideoId (): boolean {
    return typeof this.videoId === 'string' && this.videoId.length > 0
  }

  public onPlayBtnClick (): void {
    this.eventBus?.emit('play', { videoIdentifier: this.videoIdentifier })
  }
}

export default YouTubeUi

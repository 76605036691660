// Copyright © 2022 Move Closer

import { AnyObject, EventPayload } from '@movecloser/front-core'

import {
  ImageData,
  Module,
  ModuleContent,
  Related
} from '../../contexts'

import { AvailableModuleDriver } from '../allowed'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export type VideoTeaserModule = Module<VideoTeaserModuleContent, AvailableModuleDriver.VideoTeaser, VideoVersion>

export interface VideoTeaser {
  autoplay?: boolean
  description?: string
  thumbnail?: ImageData
  videoId?: string
}

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export interface VideoTeaserModuleData {
  items: VideoTeaser[]
}

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export type VideoTeaserModuleContent = VideoTeaserModuleData & ModuleContent

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export interface VideoTeaserModuleContentInput extends Omit<VideoTeaserModuleContent, 'items'> {
  items: VideoTeaserInput[]
}

export interface VideoTeaserInput extends Omit<VideoTeaserModuleContent, 'thumbnail'>, ModuleContent {
  thumbnail: Related | null
  id: string
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export enum VideoVersion {
  YouTube = 'youtube'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 * @author Google
 *
 * @see https://developers.google.com/youtube/iframe_api_reference#Events
 */
export enum PlayerState {
  Buffering = 3,
  Ended = 0,
  Paused = 2,
  Playing = 1,
  Unstarted = -1,
  VideoCued = 5
}

/**
 * Event payload of the `ui:yt-player.state-changed` event.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type UiYTPlayerStateChangedEventPayload = EventPayload<{
  /**
   * The YT player instance that fired (emitted) the event.
   */
  player: AnyObject

  /**
   * Current state of the given player.
   */
  state: PlayerState
}>
